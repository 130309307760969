import React from "react"

import Layout from "~/components/common/Layout"
import { getBackLink, getHeaderTitles, getNextLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"
import { graphql, useStaticQuery } from "gatsby"
import HeroVideo from "~/components/page/HeroVideo"
import { InfoboxDetailed } from "~/components/page/Infobox"
import { Box, Flex } from "reflexbox"
import Img from "gatsby-image"
import { Text } from "~/components/common/Typography"
import Copyright from "~/components/common/Copyright"
import Source from "~/components/common/Source"
import { mq } from "~/components/common/theme"

const mqMobileTablet = mq({
  display: ["block", "block", "none"],
})
const mqDesktop = mq({
  display: ["none", "none", "block"],
})

const Page = ({ myPage = 2, mySubpage = 5 }) => {
  const data = useStaticQuery(graphql`
      query {
          hero: file(name: { eq: "6.6HERO" }) {
              childImageSharp {
                  fluid(maxWidth: 1200, maxHeight: 500, cropFocus: NORTH, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1: file(name: { eq: "6.6BOTTOM" }) {
              childImageSharp {
                  fluid(maxWidth: 800, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
          graphic1Mobile: file(name: { eq: "6.6BOTTOM" }) {
              childImageSharp {
                  fluid(maxWidth: 800, maxHeight: 700, quality: 90, cropFocus: NORTH) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
              }
          }
      }
  `)

  const { hero, graphic1, graphic1Mobile } = data

  return (
    <Layout
      myPage={myPage}
      mySubpage={mySubpage}
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
        nextLink: `${getNextLink(myPage, mySubpage)}`,
      }}
    >
      <HeroVideo
        headingLarge="Playing sports impacts girls’ health in the future. As women, they are more likely to be active, happy, energetic, and healthy. And they are less likely to have health-related issues in the long term."
        image={hero}
        bg={"white"}
      />

      <InfoboxDetailed
        headerText={"From girls to women"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
      >
        <Flex flexDirection={["column", "column", "row"]} mt={6}>
          <Box
            width={["100%", "100%", "50%"]}
            sx={{
              ">p": {
                marginBottom: 6,
              },
            }}
            textAlign={"left"}
            pr={[0, 0, 6]}
          >
            <Text mt={0}>
              Women who were active as children enjoy the protective effect on
              health in later years and experience lower incidence of
              osteoporosis, diabetes, high blood pressure, heart disease,
              obesity, and endometrial, colon, and breast cancers.
              <Source>78</Source>
            </Text>
            <Text>
              Women who had the highest physical activity during adolescence and
              young adulthood were 20% less likely to get breast cancer,
              according to the findings from 23 studies.<Source>79</Source>
            </Text>
            <Text>
              Women who have grown up playing sports are more motivated to stay
              active as adults because they have experienced higher levels of
              neurotransmitters such as dopamine and serotonin. In other words,
              their brains have an established reward-feedback loop associated
              with exercise, which keeps women motivated to exercise.
              <Source>80</Source>
            </Text>
            <Text>
              Women who exercise have healthier weights, lower levels of blood
              sugar, cholesterol and triglycerides, and lower blood pressure
              than women who don’t exercise.<Source>81</Source>
            </Text>
            <Text>
              Women experience fewer symptoms of depression in young adulthood
              if they participated in sports in high school.<Source>82</Source>
            </Text>
          </Box>
          <Flex width={["100%", "100%", "50%"]}>
            <Box width={"100%"} mb={[5, 5, 0]} mt={4}>
              <Img
                css={mqDesktop}
                fluid={graphic1.childImageSharp.fluid}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
              <Img
                css={mqMobileTablet}
                fluid={{
                  ...graphic1Mobile.childImageSharp.fluid,
                }}
                margin={"0 auto"}
                loading={"eager"}
              ></Img>
            </Box>
          </Flex>
        </Flex>
      </InfoboxDetailed>

      <InfoboxDetailed
        headerText={
          "Participation in sports extends beyond the life of just the girl who plays. "
        }
        headerMaxWidth={"desktop"}
        maxWidth={"100%"}
        contentMaxWidth={"desktop"}
        bg={"seafoam"}
        headerBackground={"transparent"}
        pt={6}
      >
        <Box bg={"white-panel"} mt="5" px={[5, 5, 6]} py={[6, 6]}>
          <Flex flexDirection={"column"} maxWidth={"mediumContent"} mx={"auto"}>
            <Text>
              Scientists have found evidence of exercise-related benefits even
              earlier in life, passed on by mothers who exercise during
              pregnancy or even prior to conception.
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["36px", "36px"]}
              fontFamily={"PermanentMarkerRegular"}
              color={"grey-text"}
              mt={3}
              mb={2}
            >
              “Given that the mother is sharing her circulation with the
              offspring, all kinds of changes are passed on in terms of hormone
              levels or blood lipid levels, all of which affect the energy
              storage, oxygen capacity and muscle health of the child at birth.
              This has an impact on their natural ability to exercise, but also
              perhaps on their motivation to exercise at a subconscious level.
              This will then go on to benefit them throughout life.”
            </Text>
            <Text
              uppercase
              fontFamily={"AdineuePROBoldWeb"}
              fontSize={["16px", "16px"]}
              lineHeight={["18px", "21px"]}
            >
              —Ted Garland, University of California<Source>83</Source>
            </Text>
            <Text
              uppercase
              fontSize={["18px", "24px"]}
              lineHeight={["27px", "36px"]}
              fontFamily={"AdineuePROBoldWeb"}
            >
              Research suggests that if people play sports, their daughters will
              be more likely to play.
            </Text>
            <Text>
              The more girls participate now, the more future girls will have
              mothers to look up to. With role models who can speak to their own
              experiences, girls will feel more confident in their abilities to
              thrive in intense competition and believe that they are tough.
            </Text>
            <Text>
              Girls who play sports can become mothers, aunts, coaches, and
              teachers who show girls that being athletic and being feminine are
              not mutually exclusive.
            </Text>
            <Text>
              A 2004 study by researchers at the University of Southern
              California suggests that if a mother plays or played organized
              sports, her daughter is more likely to play.<Source>84</Source>
            </Text>
          </Flex>
        </Box>
      </InfoboxDetailed>

      <Copyright bg={"seafoam"}/>
    </Layout>
  )
}

export default withProgress(Page, 5, 5)
